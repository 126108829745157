<template  lang="pug">
	v-container(class="section-version")
		v-row(justify="center")
			v-col( cols="12" lg="10" )
				
				h1(class="display-1") Historial de versiones

				h2(class="title mt-5") <span class="font-weight-black">7.0 Recomendador CAT 2024-07-19</span>
					ul 
						li Interfaz CAT se renombra a Recomendador CAT
						li Se reescriben y mejoran todos los submodulos de Recomendador CAT
						li Desacoplo de todos los servicios que se consumen desde Recomendador CAT. Ahora los servicios que se consumen desde el Recomendador CAT ya no se sirven desde el management si no desde servicios aparte creados en ExpressJS con MongoDB lo que trae mejoras en latencia del modulo en general.
						li Se agregan datalayers en todos las secciones de Recomendador CAT
						li Creación de modulo Buzón de Mensajes dentro del panel.

				h2(class="title mt-5") <span class="font-weight-black">6.0 Actualización a Django 4.2 2023-06-21</span>
					ul 
						li Actualizacón a Django 4.2 en el management
						li Creacion de sección motor de audiencias
						li Actualización de queries en el management para las secciones de los segmentadores (reemplazo de tabla ecommerce-bi.SourceTables.invalid_domain por ecommerce-bi.digitalanalytics.DominiosInvalidos).
						li Creacion de pestaña "por menos de" en la interfaz CAT


				h2(class="title mt-5") <span class="font-weight-black">5.12 Interfaz CAT 2023-04-26</span>
					ul 
						li Creación de seleccionador pantallas
						li Creación de seleccionador salas
						li Creación de seleccionador cocinas 
						li Carrusel fulfillment 
						li Carrusel combos 
						li Nueva información de artículos en carruseles flotantes de vistas, combras, busquedas, vistas y compras
						li Mejoras visuales a carruseles

				h2(class="title mt-5") <span class="font-weight-black">5.11 Interfaz CAT 2023-03-31</span>
					ul 
						li Creación de seleccionador colchones
						li Creación de seleccionador Refigeradores
						li Creación de seleccionador Estufas

				h2(class="title mt-5") <span class="font-weight-black">5.10 Interfaz CAT 2023-03-22</span>
					ul 
						li Creación de seleccionador motocicletas
						li Creación de seleccionador celulares
						li Creación de seleccionador lavadoras / centros de lavado


				h2(class="title mt-5") <span class="font-weight-black">5.9 Interfaz CAT 2023-03-01</span>
					ul 
						li Creación de carrusel modelo cross-selling + límite de precios en pestaña recomendaciones por artículo ( carrusel con nombre artículos complementarios con limite de precios).
						li Carruseles de recomendaciones personalizadas de últimas transacciones, en pestaña recomendaciones personalizadas. (Carruseles interactivos dentro de ventanas emergentes con los nombres: porque el cliente vio, porque el cliente compró, porque el cliente buscó y porque el cliente vio y compró)

				h2(class="title mt-5") <span class="font-weight-black">5.8 Interfaz CAT 2023-02-27</span>
					ul 
						li Creación de seleccionador calentadores

				h2(class="title mt-5") <span class="font-weight-black">5.7.1 Interfaz CAT 2023-02-23</span>
					ul 
						li Creación de seleccionador Clima y Ventilacion
						li Correccion de issues de los seleccionadores de computo, bateria y llantas debido cambios y ajustes en las queries en el lado del management (backend).

				h2(class="title mt-5") <span class="font-weight-black">5.6 Interfaz CAT 2023-01-04</span>
					ul 
						li Creación de seleccionador baterias

				h2(class="title mt-5") <span class="font-weight-black">5.5 Interfaz CAT 2022-12-28</span>
					ul 
						li Creación de seleccionador computo

				h2(class="title mt-5") <span class="font-weight-black">5.4 Porcentaje Completitud 2022-11-29 </span>
					ul 
						li Creación de seccion porcentaje completitud

				h2(class="title mt-5") <span class="font-weight-black">5.3 Nueva pestaña seleccionador de productos en Interfaz CAT 2022-11-01 </span>
					ul 
						li Se agregó la pestaña seleccionadores de producto a la seccion Interfaz CAT 
						li Se creo seleccionador de llantas en pestaña seleccionadores de producto de la sección Interfaz CAT
				h2(class="title mt-5") <span class="font-weight-black">5.2 Soporte Google Ads en formularios casos de uso 2022-10-21 </span>
					ul 
						li Se agregó soporte para el envio de audiencias a Google Ads desde la seccion segmentraciones dinamicas para casos de uso
				
				h2(class="title mt-5") <span class="font-weight-black">5.1 Pestaña Top de Productos 2022-10-20 </span>
					ul 
						li Se creo la pestaña Recomendaciones genericas, actual top de productos en la sección Interfaz CAT

				h2(class="title mt-5") <span class="font-weight-black">5.0 Sección Interfaz CAT 2022-08-18 (y dias anteriores)</span>

					ul 
						li Creación de la sección Interfaz CAT. 
						li Creación de la pestañia Recomendacioones presonalizadas dentro de Interfaz CAT 
						li Creación de la pestaña Recomendaciones por artículo dentro de Interfaz CAT
				
				h2(class="title mt-5") <span class="font-weight-black">4.3 Logs de los Formularios 2021-08-16</span>
					ul 
						li Sección llamada "Logs de los Formularios" para listar los registros de las peticiones hechas para generar archivos CSV de acuerdo a los datos recabados en los formularios "casos de uso" y "segmentaciones y recomendaciones".
						li Nueva pestaña llamada Audiencias dentro de la seccion "Logs de los Formularios", para listar los registros de creación de nuevas audiencias, generados con el formulario "segmentaciones y recomendaciones".
						li Funcionalidad para permitir el acceso a la sección "Logs de los Formularios" solo a los usuarios con el permiso requerido.

				h2(class="title mt-5") <span class="font-weight-black">4.2 Generación de Audiencias en Google Ads 2021-08-09</span>
					ul 
						li Funcionalidad de mandar audiencias a Google Ads mejorada. Ahora se manda a crear una audiencia en Google Ads haciendo dos llamadas a endpoints diferentes. La primera llamadas crea las configuraciones necesarias dentro de la infraestructura programada y la segunda llamada manda a crear la audiencia. Lo anterior expuesto con ayuda de un encolador de tareas, permitiendo una optimizacion en el uso de los recursos y experiencia de usuario.
						li Funcionalidad de agendar creacion de audiencias en Google Ads de acuerdo a rangos de fechas especificados en el formulario de "segmentaciones y recomendaciones".

				h2(class="title mt-5") <span class="font-weight-black">4.1 Paid Media 2021-05-31</span>
					ul 
						li Nuevo rol de Paid Media 
						li Nueva funcionalidad de mandar a crear audiencias en Google Ads con los datos deleccionados en el formulario "segmentaciones y recomendaciones".


				h2(class="title mt-5") <span class="font-weight-black">4.0 Segmentación personalizada 2021-04-31 </span>
					ul 
						li Compatibilidad de la interfaz para usarse como PWA
						li Upgrade del framework del management a la version 2.2.18 LTS
						li Se agregarón niveles de seguridad por roles de usuario
						li Mejora en los tiempos de respuesta del management por la integracion de Nginx y Gunicorn
						li Actualización del favicon y los recursos de imagenes usados por la interfaz como PWA

				h2(class="title mt-5") <span class="font-weight-black">3.3 Segmentador 2021-02-19 </span>
					ul 
						li Se extiende el modulo de segmentación personalizada

				h2(class="title mt-5") <span class="font-weight-black">3.2.1 Rankin 2021-02-19 </span>
					ul 
						li Se agrego la columna de talla  sobre la pestaña de productos dentro de la sección de productos

				h2(class="title mt-5") <span class="font-weight-black">3.2 Modulo de Ranking 2021-02-19 </span>
					ul 
						li Se habilita la opción para leer y descargar resultados de categorías y productos
						li se habilita la opción de subir CSV  para cargar ítems a la tabla de productos

				h2(class="title mt-5") <span class="font-weight-black">3.1 Segmentación 2020-05-05 </span>
					ul 
						li Creacion de la sección Alertas existencia.
						li Grafica de Historial de existencia.
						li Graficas tipo pie  de las existencia de cada bodega.
						li Tabla con todas las Alertas.

				h2(class="title mt-5") <span class="font-weight-black">3.0 Segmentación 2020-04-22 </span>
					ul 
						li Generacion de segmentos  por medio de SPC y RFM.
						li Activación de xselling.
						li Activación de filtros usando variables estatiticas de clientes.
						li Envío de segmentos a Emarsys.
				h2(class="title mt-5 ") <span class="font-weight-black"> 2.0.1 2020-03-15 </span>
					ul 
						li Envío de fotos en items de  SKU PIM.
						li Envío de notificación cuando se realiza una actulización de  SKU en la sección PIM.
				h2(class="title mt-5") <span class="font-weight-black"> 2.0 Cloud 2020-02-15 </span>
					ul 
						li Generacion de segmentos  por medio de SPC y RFM.
				
				h2(class="title mt-5") <span class="font-weight-black">1.0 On-premisse 2019-11-01</span>
					ul 
						li Segmentación de clientes atraves de spc, xselling y recompra.

				
				
				

				
				


</template>
<script>

	export default {
		name: 'DashboardPim',

		data() {
			return {
				// a espera
				history: [
					{
						title: '',
						features: [
							''
						]
					}
				]
			}
		},
		components:{
		},
		mounted(){
		},
		methods:{
		},
		
	
	}
</script>
